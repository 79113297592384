import { useEffect } from "react";
import Button from "components/Button";
import { Link } from "react-router-dom";
import BackgroundSlider from "./BackgroundSlider";
import { REACT_APP_ASSETS_URL } from "constants/constant";
import "./style.css";

export default function ComingSoon() {
  return (
    <div className="comingsoon relative w-screen h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-screen">
        <BackgroundSlider />
      </div>
      <div className="relative flex flex-col gap-8 lg:gap-14 z-10">
        <div className="flex items-center">
          <h1 className="text-[34px] lg:text-4xl xl:text-[150px] font-bold font-serif leading-none tracking-tight text-white uppercase">
            coming soon!
          </h1>
          <img
            src={`${REACT_APP_ASSETS_URL}cs-char.webp`}
            alt="characters"
            className="relative -m-2 lg:-m-10 w-[40px] md:max-h-[150px] lg:max-h-[200px] object-contain md:w-[80px] lg:w-[100px]"
          />
        </div>

        <div className="relative flex items-center justify-center gap-2 lg:gap-4 z-10">
          <Link
            to="/"
            className="inline-flex justify-center items-center primary-button p-[15px] lg:p-[24px] bg-transparent text-sm  lg:text-base leading-none font-400 font-serif border-2 rounded-full "
          >
            Go Back
          </Link>
          <Link
            to="https://twitter.com/maians_io"
            target="_blank"
            className="inline-flex items-center justify-center md:w-fit bg-transparent text-sm lg:text-base leading-none font-400 font-serif rounded-full outline-button twitter p-4 lg:p-[20px] h-[48px] md:h-[61px] text-white border-2"
          >
            Join Us on
            <span className="inline-block ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="19"
                viewBox="0 0 21 19"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.0021 10.0692L0.509766 0.611328H6.44422L11.0692 6.45692L16.0102 0.637648H19.2786L12.6495 8.45427L20.5098 18.3891H14.5931L9.58515 12.0674L4.23873 18.3716H0.952636L8.0021 10.0692ZM15.4555 16.6367L4.14858 2.36369H5.58115L16.8738 16.6367H15.4555Z"
                  fill="white"
                />
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
