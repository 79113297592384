import { useEffect } from "react";
import AiPowered from "./components/AiPowered";
import BrandEmpowerment from "./components/BrandEmpowerment";
import BuiltForScale from "./components/BuiltForScale";
import HeroSection from "./components/HeroSection";
import Marketplace from "./components/Marketplace";
import SecureIdentity from "./components/SecureIdentity";
import SocialRevolution from "./components/SocialRevolution";
import Footer from "components/Footer";
import Header from "components/Header";

import "./style.css";
import Partners from "./components/Partners";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main className="relative w-full pt-[100px] lg:pt-[180px]">
        <HeroSection />
        <BuiltForScale />
        <AiPowered />
        <Marketplace />
        <BrandEmpowerment />
        <SecureIdentity />
        <Partners />
        <SocialRevolution />
      </main>

      <Footer />
    </>
  );
}
