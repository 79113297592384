import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { REACT_APP_ASSETS_URL } from "constants/constant";

const path01Variants = {
  open: { d: "M3.06061 2.99999L21.0606 21" },
  closed: { d: "M0 9.5L24 9.5" },
};

const path02Variants = {
  open: { d: "M3.00006 21.0607L21 3.06064" },
  moving: { d: "M0 14.5L24 14.5" },
  closed: { d: "M0 14.5L15 14.5" },
};

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};

export default function Header() {
  const [isOpen, setOpen] = useState(false);
  const path01Controls = useAnimation();
  const path02Controls = useAnimation();

  const onClick = async () => {
    setOpen(!isOpen);
    if (!isOpen) {
      await path02Controls.start(path02Variants.moving);
      path01Controls.start(path01Variants.open);
      path02Controls.start(path02Variants.open);
      document.body.classList.add("overflow-hidden");
    } else {
      path01Controls.start(path01Variants.closed);
      await path02Controls.start(path02Variants.moving);
      path02Controls.start(path02Variants.closed);
      document.body.classList.remove("overflow-hidden");
    }
  };
  return (
    <>
      <nav className="fixed top-[20px] w-full z-50 px-2 lg:px-4">
        <div className="navbar-nav max-w-[1800px] flex flex-wrap items-center justify-center lg:justify-between mx-auto bg-nav-bg rounded-full p-3 lg:py-[19px] lg:px-[25px] xl:px-[32px] border-[1px] border-border">
          <Link
            to="/"
            className="relative flex items-center space-x-3 rtl:space-x-reverse z-20"
          >
            <img
              src={`${REACT_APP_ASSETS_URL}maians.svg`}
              className="h-[30px] md:h-[36px] xl:h-[46px]"
              alt="maians"
            />
          </Link>
          {/* <div className="relative flex items-center md:order-2 space-x-3 lg:space-x-0 rtl:space-x-reverse z-20">
            <div className="profile relative p-[5px] opacity-50">
              <button
                type="button"
                className="relative inline-flex justify-center items-center gap-3 cursor-not-allowed"
              >
                <div className="relative w-[36px] h-[36px] md:w-[43px] md:h-[43px] inline-flex items-center justify-center rounded-full overflow-hidden">
                  <img
                    src={`${REACT_APP_ASSETS_URL}dp.webp`}
                    alt="profile"
                    className="object-cover"
                  />
                </div>
                <span className="w-[18px] h-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>

            <button
              onClick={onClick}
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm bg-transparent lg:hidden"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="relative rotate-180"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <motion.path
                  {...path01Variants.closed}
                  animate={path01Controls}
                  transition={{ duration: 0.2 }}
                  stroke="#FFFFFF"
                  strokeWidth="2"
                />
                <motion.path
                  {...path02Variants.closed}
                  animate={path02Controls}
                  transition={{ duration: 0.2 }}
                  stroke="#FFFFFF"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div> */}
          <div
            className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
            id="navbar-sticky"
          >
            <ul className="w-full font-serif flex flex-col justify-center mt-4 lg:space-x-1 xl:space-x-2 rtl:space-x-reverse lg:flex-row lg:mt-0 z-10">
              <li>
                <button className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed">
                  about
                  <span className="w-[18px] h-[18px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              <li>
                <button className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed">
                  Marketplace
                  <span className="w-[18px] h-[18px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              <li>
                <button className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed">
                  Maian studio
                  <span className="w-[18px] h-[18px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </li>
              {/* <li>
                <a
                  href="#"
                  className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed"
                >
                  token
                </a>
              </li> */}
              <li>
                <div className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed">
                  discover
                </div>
              </li>
              <li>
                <div className="nav-link text-sm px-4 py-[10px] flex items-center justify-center gap-[10px] capitalize rounded-full hover:text-lime hover:bg-dim-white-06 cursor-not-allowed">
                  AI
                </div>
              </li>

              {/* <div className="dropdown-menu w-full absolute left-0 top-[-8px] h-auto z-[-1] px-[77px] pt-[100px] pb-[45px]">
                <div className="relative w-full h-full flex items-start justify-between">
                  <div className="relative">
                    <div className="flex items-center mb-[50px]">
                      <div className="w-[42px] h-[42px] flex items-center justify-center rounded-full bg-dim-white-06">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g opacity="0.64">
                            <path
                              d="M21 21.5L17.5104 18.0104M17.5104 18.0104C19.0486 16.4722 20 14.3472 20 12C20 7.30558 16.1944 3.5 11.5 3.5C6.80558 3.5 3 7.30558 3 12C3 16.6944 6.80558 20.5 11.5 20.5C13.8472 20.5 15.9722 19.5486 17.5104 18.0104Z"
                              stroke="#FFF5F5"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="ml-[10px]">
                        <h4 className="text-sm font-bold capitalize mb-2">
                          Explore
                        </h4>
                        <p className="opacity-65">
                          Explore the Virtuan Builder Now!
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-[42px] h-[42px] flex items-center justify-center rounded-full bg-dim-white-06">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g opacity="0.64">
                            <path
                              d="M21 21.5L17.5104 18.0104M17.5104 18.0104C19.0486 16.4722 20 14.3472 20 12C20 7.30558 16.1944 3.5 11.5 3.5C6.80558 3.5 3 7.30558 3 12C3 16.6944 6.80558 20.5 11.5 20.5C13.8472 20.5 15.9722 19.5486 17.5104 18.0104Z"
                              stroke="#FFF5F5"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="ml-[10px]">
                        <h4 className="text-sm font-bold capitalize mb-2">
                          Explore
                        </h4>
                        <p className="opacity-65">
                          Explore the Virtuan Builder Now!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex items-center">
                      <div className="w-[42px] h-[42px] flex items-center justify-center rounded-full bg-dim-white-06">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g opacity="0.64">
                            <path
                              d="M21 21.5L17.5104 18.0104M17.5104 18.0104C19.0486 16.4722 20 14.3472 20 12C20 7.30558 16.1944 3.5 11.5 3.5C6.80558 3.5 3 7.30558 3 12C3 16.6944 6.80558 20.5 11.5 20.5C13.8472 20.5 15.9722 19.5486 17.5104 18.0104Z"
                              stroke="#FFF5F5"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="ml-[10px]">
                        <h4 className="text-sm font-bold capitalize mb-2">
                          Explore
                        </h4>
                        <p className="opacity-65">
                          Explore the Virtuan Builder Now!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </ul>
          </div>
        </div>
      </nav>

      {/* <motion.div
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5 }}
        className={`fixed top-0 w-screen h-screen bg-bg z-40 lg:hidden`}
      >
        <div className="relative mt-28 mb-4 p-4 h-full overflow-y-auto bg-bg">
          <ul className="absolute left-2/4 -translate-x-1/2 w-full font-serif flex flex-col lg:px-[20px] xl:px-[77px] mt-4 lg:space-x-1 xl:space-x-2 rtl:space-x-reverse lg:flex-row lg:mt-0 z-10">
            <li>
              <button className="nav-link text-xl md:text-xxl px-4 py-[10px] flex items-center gap-[10px] capitalize cursor-not-allowed">
                about
                <span className="w-[18px] h-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li>
              <button className="nav-link text-xl md:text-xxl px-4 py-[10px] flex items-center gap-[10px] capitalize cursor-not-allowed">
                Marketplace
                <span className="w-[18px] h-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li>
              <button className="nav-link text-xl md:text-xxl px-4 py-[10px] flex items-center gap-[10px] capitalize cursor-not-allowed">
                Maian studio
                <span className="w-[18px] h-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M13.4401 6.13501H8.76756H4.56006C3.84006 6.13501 3.48006 7.00501 3.99006 7.51501L7.87506 11.4C8.49756 12.0225 9.51006 12.0225 10.1326 11.4L11.6101 9.92251L14.0176 7.51501C14.5201 7.00501 14.1601 6.13501 13.4401 6.13501Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li>
              <div className="nav-link text-xl md:text-xxl px-4 py-[10px] flex items-center gap-[10px] capitalize cursor-not-allowed">
                discover
              </div>
            </li>
            <li>
              <div className="nav-link text-xl md:text-xxl px-4 py-[10px] flex items-center gap-[10px] capitalize cursor-not-allowed">
                AI
              </div>
            </li>
          </ul>
        </div>
      </motion.div> */}
    </>
  );
}
