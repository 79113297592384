import React from "react";

function Button({
  className,
  leftIcon,
  rightIcon,
  text,
  twitterIcon,
  iconButton,
  onClickHandler,
}) {
  return (
    <button
      className={`inline-flex justify-center items-center bg-transparent text-sm  lg:text-base leading-none font-400 font-serif border-2 rounded-full ${className}`}
      onClick={onClickHandler}
    >
      {leftIcon && (
        <span className="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M3.375 15.125L14.625 3.875L3.375 15.125ZM14.625 3.875H6.1875H14.625ZM14.625 3.875V12.3125V3.875Z"
              fill="white"
            />
            <path
              d="M3.375 15.125L14.625 3.875M14.625 3.875H6.1875M14.625 3.875V12.3125"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}

      {text}

      {rightIcon && (
        <span className="ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M3.375 15.125L14.625 3.875L3.375 15.125ZM14.625 3.875H6.1875H14.625ZM14.625 3.875V12.3125V3.875Z"
              fill="white"
            />
            <path
              d="M3.375 15.125L14.625 3.875M14.625 3.875H6.1875M14.625 3.875V12.3125"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}

      {twitterIcon && (
        <span className="inline-block ml-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.0021 10.0692L0.509766 0.611328H6.44422L11.0692 6.45692L16.0102 0.637648H19.2786L12.6495 8.45427L20.5098 18.3891H14.5931L9.58515 12.0674L4.23873 18.3716H0.952636L8.0021 10.0692ZM15.4555 16.6367L4.14858 2.36369H5.58115L16.8738 16.6367H15.4555Z"
              fill="white"
            />
          </svg>
        </span>
      )}

      {iconButton && (
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.4291 18.8201C14.2391 18.8201 14.0491 18.7501 13.8991 18.6001C13.6091 18.3101 13.6091 17.8301 13.8991 17.5401L19.4391 12.0001L13.8991 6.46012C13.6091 6.17012 13.6091 5.69012 13.8991 5.40012C14.1891 5.11012 14.6691 5.11012 14.9591 5.40012L21.0291 11.4701C21.3191 11.7601 21.3191 12.2401 21.0291 12.5301L14.9591 18.6001C14.8091 18.7501 14.6191 18.8201 14.4291 18.8201Z"
              fill="#292D32"
            />
            <path
              d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
              fill="#292D32"
            />
          </svg>
        </span>
      )}
    </button>
  );
}

export default Button;
