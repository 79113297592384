import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";
import { REACT_APP_ASSETS_URL } from "constants/constant";

export default function BrandEmpowerment() {
  return (
    <div className="brandEmpower relative px-4 pb-16 pt-8 lg:pt-[100px] xl:pt-[100px] xxl:pt-[120px] overflow-hidden">
      <div className="effects absolute top-0 left-0 w-full h-full z-20"></div>

      <div className="relative mx-auto max-w-[1800px] z-30">
        <div className="relative max-w-[932px] mx-auto text-center z-10">
          <SectionTag text="Craft Digital Connections" />
          <SectionTitle text="Brand Empowerment" highligh />
          <SectionDetail
            text="Transform your brand's digital landscape with Maians: Forge unique
            digital identities, launch exclusive merchandise, and immerse
            audiences in spellbinding virtual events. Maians is your gateway to
            groundbreaking engagement and innovative marketing vistas. Propel
            your brand into a future where connections are deeper, experiences
            more memorable, and your digital footprint becomes legendary."
          />
        </div>
      </div>

      <div className="brandEmpower__bottom relative h-[250px] md:h-[450px] xl:h-[793px] p-4 flex align-center justify-center mt-8 lg:mt-[80px]">
        <div className="brandEmpower__name relative w-full h-full flex items-center justify-center mx-auto max-w-[1800px] px-4 z-10">
          <div className="name w-[90%] absolute">
            <svg
              className="relative"
              viewBox="0 0 1182 340"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M152.441 267.095L143.545 165.213L126.895 266.865H63.2607L93.8236 81.0005H188.477L194.864 159.936L230.672 81.0005H323.045L292.711 266.865H229.076L245.954 163.378L198.057 266.865H152.441V267.095Z"
                fill="#FFF5F5"
              ></path>
              <path
                d="M498.898 267.096L529.461 81.2314H595.833L565.27 267.096H498.898Z"
                fill="#FFF5F5"
              ></path>
              <path
                d="M887.089 267.095L849.684 173.933L834.63 267.095H768.259L798.594 81.23H864.965L903.967 163.148L917.424 81.0005H983.796L953.461 266.865H887.089V267.095Z"
                fill="#FFF5F5"
              ></path>
              <path
                d="M1051.99 271.68C997.479 271.68 968.969 235.425 973.531 198.252H1033.97C1033.97 207.89 1038.53 219.363 1054.04 222.575C1080.96 227.623 1094.87 198.711 1059.97 194.122C1018.01 188.156 985.391 172.093 992.69 131.479C999.304 95.4529 1039.67 78.9316 1077.08 78.9316C1123.61 78.9316 1157.14 105.09 1155.77 148L1098.29 146.853C1098.29 136.068 1089.4 125.513 1069.33 126.889C1051.99 128.037 1040.36 146.853 1069.33 150.983C1129.77 159.244 1146.42 182.878 1142.77 213.167C1137.52 248.504 1101.26 271.68 1051.99 271.68Z"
                fill="#FFF5F5"
              ></path>
              <path
                d="M370.719 81.2314H483.391V267.096H419.3V111.979L377.561 235.66L406.755 256.082L284.504 309.088L370.719 81.2314Z"
                fill="#FFF5F5"
              ></path>
              <path
                d="M646.69 81.2314H759.363V267.096H695.272V111.979L653.533 235.66L682.727 256.082L560.476 309.088L646.69 81.2314Z"
                fill="#FFF5F5"
              ></path>
            </svg>
          </div>
        </div>

        <div className="char absolute top-0 max-w-[40%] lg:max-w-[500px] xl:max-w-[784px] h-full z-30">
          <img
            src={`${REACT_APP_ASSETS_URL}brand-char.webp`}
            alt="brand empowerment"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
}
