import React, { useRef, useState, useEffect } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { REACT_APP_ASSETS_URL } from "constants/constant";
import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";

export default function BuiltForScale() {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
  });

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1366);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const scaleDimensions = () => {
    return isMobile ? [1, 1] : [0.9, 1];
  };

  const rotate = useTransform(scrollYProgress, [0, 1], [10, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions());
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100]);

  return (
    <div
      className="builtForScale relative px-4 pb-10 pt-16 lg:pt-[100px]  xl:pt-[140px] xxl:pt-[180px]"
      ref={containerRef}
    >
      <div className="relative mx-auto max-w-[1800px]">
        <div className="relative max-w-[884px] mx-auto text-center z-10">
          <SectionTag text="Dream Big" />
          <SectionTitle text="Built for Scale" highligh />
          <SectionDetail
            text="Maians: Pioneering the Next Frontier of Digital Interaction. Through
            collaboration with Metagravity, our technology empowers the seamless
            coexistence of over 100,000 avatars without a hint of performance
            degradation. Revolutionizing online engagement, Maians set an
            unparalleled standard for the future of gaming, metaverses, and
            virtual experiences."
          />
        </div>

        <div className="built-bg-effect relative w-full flex align-center justify-center mt-8 lg:mt-[64px]">
          <div className="video-holder rounded-[16px] lg:rounded-[32px] overflow-hidden">
            <div
              className="relative w-full h-full lg:min-h-[832px] z-10"
              style={{
                perspective: "1000px",
              }}
            >
              <motion.div
                className="w-full h-full rounded-[16px] lg:rounded-[32px] overflow-hidden"
                style={{
                  rotateX: rotate,
                  scale,
                }}
                layout
              >
                <video
                  loading="lazy"
                  poster={`${REACT_APP_ASSETS_URL}built-vd-poster.webp`}
                  autoPlay
                  loop
                  muted
                  playsInline
                  style={{ translateY: translate }}
                >
                  <source
                    src={`${REACT_APP_ASSETS_URL}built-for-scale.mp4`}
                    type="video/mp4"
                  />
                </video>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
