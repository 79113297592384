import React from "react";
import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";
import { REACT_APP_ASSETS_URL } from "constants/constant";

export default function Partners() {
  return (
    <div className="partners relative pb-16 pt-8 lg:pt-[100px]  xl:pt-[100px] xxl:pt-[120px]">
      <div className="relative mx-auto max-w-[1800px] px-4 z-30">
        <div className="relative max-w-[932px] mx-auto text-center z-10">
          <SectionTag text="Pioneering Partnerships" />
          <SectionTitle text="Partners" highligh />
          <SectionDetail text="To bring Maians to life, we proudly collaborate with world-leading technology partners." />
        </div>

        <div className="relative w-full max-w-[1500px] mx-auto flex align-center justify-center mt-8 lg:mt-[80px]">
          <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4 md:gap-5">
            <div className="partnerCard flex items-center justify-center h-full w-full md:w-[50%] min-h-[180px] md:min-h-[200px] lg:min-h-[300px] xl:min-h-[420px] p-8 rounded-2xl cursor-pointer">
              <img
                src={`${REACT_APP_ASSETS_URL}vanar-logo.webp`}
                alt="vanar"
                className="max-w-[70%]"
              />
            </div>
            <div className="partnerCard flex items-center justify-center h-full w-full md:w-[50%] min-h-[180px] md:min-h-[200px] lg:min-h-[300px] xl:min-h-[420px] p-8 rounded-2xl cursor-pointer">
              <img
                src={`${REACT_APP_ASSETS_URL}metagravity.webp`}
                alt="metagravity"
                className="max-w-[70%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
