import { REACT_APP_ASSETS_URL } from "constants/constant";
import React from "react";
import Slider from "react-slick";

export default function BackgroundSlider() {
  var settings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 15000,
    cssEase: "linear",
    infinite: true,
    arrows: false,
  };
  return (
    <Slider {...settings} className="flex items-center justify-center">
      <div className="slide w-full h-screen">
        <img src={`${REACT_APP_ASSETS_URL}cs-slide1.webp`} alt="maians" className="h-screen" />
      </div>
      <div className="slide w-full h-screen">
        <img src={`${REACT_APP_ASSETS_URL}cs-slide2.webp`} alt="maians" className="h-screen" />
      </div>
      <div className="slide w-full h-screen">
        <img src={`${REACT_APP_ASSETS_URL}cs-slide3.webp`} alt="maians" className="h-screen" />
      </div>
    </Slider>
  );
}
