import React from "react";

export function SectionTitle({ text, highligh, className }) {
  return (
    <h2
      className={`${className} text-2xl lg:text-4xl xl:text-5xl font-bold font-serif leading-none lg:leading-[94px] tracking-tight text-white`}
    >
      {text}
      {highligh && <span className="text-lime">.</span>}
    </h2>
  );
}

export function SectionDetail({ text, className }) {
  return (
    <p
      className={`${className} mt-5 lg:mt-[50px] text-sm md:text-base lg:text-xl font-medium leading-2 lg:leading-7`}
    >
      {text}
    </p>
  );
}

export function SectionTag({ text }) {
  return (
    <div
      className={`text-xsm md:text-base lg:text-xl font-medium leading-[14px] rounded-full text-tag-text bg-tag-bg py-[12px] px-[16px] w-fit mx-auto mb-[15px]`}
    >
      {text}
    </div>
  );
}
