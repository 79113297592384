import { ParallaxSlider } from "components/ParallaxSlider";
import Button from "components/Button";
import { REACT_APP_ASSETS_URL } from "constants/constant";
import { Link } from "react-router-dom";

export default function HeroSection() {
  return (
    <div className="heroSection blur-image-effect relative px-4 z-[2]">
      <div className="mx-auto max-w-[1800px] min-h-[689px]">
        <div className="wrap relative rounded-3xl grid grid-cols-auto lg:grid-cols-3 overflow-hidden hero-bgImg">
          <div className="mx-auto text-center absolute self-end justify-center px-2 py-8 lg:pl-[60px] lg:pb-[50px] lg:relative lg:self-end lg:mx-0 lg:text-left lg:min-w-[600px] z-30">
            <div className="text-xsm lg:text-sm font-medium leading-[12px] rounded-full text-tag-text bg-tag-bg py-[12px] px-[16px] w-fit mx-auto mb-[15px] lg:mx-0">
              Your Personal Avatar. Powered by AI.
            </div>
            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-bold font-serif leading-none lg:leading-[95px] tracking-tight text-white uppercase">
              Maian
            </h1>
            <p className="mt-4 lg:mt-8 text-sm lg:text-base leading-2 lg:leading-7">
              Introducing Maians - A groundbreaking 3D avatar that seamlessly
              integrates AI, Social, Identity, and web3 in one revolutionary
              product. With unprecedented scalability and adaptability, Maians
              represent a new type of identity for the digital world.
            </p>

            <div className="mt-5 lg:mt-[40px]">
              <Link
                to={process.env.REACT_APP_MAIANS_BUILD_URL}
                className="outline-button inline-flex justify-center items-center bg-transparent text-sm  lg:text-base leading-none font-400 font-serif border-2 rounded-full p-[20px] text-white"
                target="_blank"
              >
                Create your Maian
                <span className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M3.375 15.125L14.625 3.875L3.375 15.125ZM14.625 3.875H6.1875H14.625ZM14.625 3.875V12.3125V3.875Z"
                      fill="white"
                    />
                    <path
                      d="M3.375 15.125L14.625 3.875M14.625 3.875H6.1875M14.625 3.875V12.3125"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>

          <div className="relative h-[689px] left-[-20%] lg:left-0 col-span-auto lg:col-span-2 z-10">
            <div className="flex flex-col antialiased items-center justify-center relative lg:right-[-25%] lg:top-[-20%] rotate-[67deg]">
              <ParallaxSlider
                items={[
                  `${REACT_APP_ASSETS_URL}card1.webp`,
                  `${REACT_APP_ASSETS_URL}card2.webp`,
                  `${REACT_APP_ASSETS_URL}card2.webp`,
                ]}
                direction="right"
                speed="fast"
              />
              <ParallaxSlider
                items={[
                  `${REACT_APP_ASSETS_URL}card4.webp`,
                  `${REACT_APP_ASSETS_URL}card5.webp`,
                  `${REACT_APP_ASSETS_URL}card6.webp`,
                ]}
                direction="left"
                speed="normal"
              />
              <ParallaxSlider
                items={[
                  `${REACT_APP_ASSETS_URL}card7.webp`,
                  `${REACT_APP_ASSETS_URL}card8.webp`,
                  `${REACT_APP_ASSETS_URL}card9.webp`,
                ]}
                direction="right"
                speed="fast"
              />
              <ParallaxSlider
                items={[
                  `${REACT_APP_ASSETS_URL}card10.webp`,
                  `${REACT_APP_ASSETS_URL}card2.webp`,
                  `${REACT_APP_ASSETS_URL}card1.webp`,
                ]}
                direction="left"
                speed="fast"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
