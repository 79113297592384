import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";
import React from "react";
import { REACT_APP_ASSETS_URL } from "constants/constant";

export default function SecureIdentity() {
  return (
    <div className="secureSection relative pb-16 pt-8 lg:pt-[100px]  xl:pt-[100px] xxl:pt-[120px]">
      <div className="relative mx-auto max-w-[1800px] px-4">
        <div className="relative max-w-[918px] mx-auto text-center z-10">
          <SectionTag text="Bridge the digital and physical" />
          <SectionTitle text="Secure Identity and Trust" highligh />
          <SectionDetail
            text="Maians offer a KYC compliant digital identity framework, allowing
            you to opt-in for verifying your Maian as a trusted identity. This
            verification unlocks the ability to trade real-world assets securely
            without revealing your true identity, fostering a safe and trusted
            environment for high-value transactions."
          />
        </div>

        <div className="blur-image-effect relative flex align-center justify-center mt-8 lg:mt-[80px]">
          <div className="relative max-w-[350px] lg:min-w-[452px] min-h-[400px] lg:min-h-[577px]">
            <div className="w-[75%] h-[75%] mx-auto">
              <video loading="lazy" poster="" autoPlay loop muted playsInline>
                <source
                  src={`${REACT_APP_ASSETS_URL}fingerprint.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="absolute top-0 -left-[50%] translate-x-2/4 w-full h-full flex items-center justify-center">
              <img
                src={`${REACT_APP_ASSETS_URL}id.webp`}
                alt="id"
                className="relative left-4 md:left-8"
              />
            </div>
            <div className="slide-up absolute bottom-0 w-full h-full"></div>

            <div className="w-full h-full">
              <span className="absolute left-0 top-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="60"
                  viewBox="0 0 59 60"
                  fill="none"
                >
                  <path
                    d="M58 1H36C16.67 1 1 16.67 1 36V59"
                    stroke="#9EFF00"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <span className="absolute right-0 top-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="60"
                  viewBox="0 0 59 60"
                  fill="none"
                >
                  <path
                    d="M1 1H23C42.33 1 58 16.67 58 36V59"
                    stroke="#9EFF00"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <span className="absolute bottom-0 left-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="60"
                  viewBox="0 0 59 60"
                  fill="none"
                >
                  <path
                    d="M58 59H36C16.67 59 1 43.33 1 24V1"
                    stroke="#9EFF00"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
              <span className="absolute bottom-0 right-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59"
                  height="60"
                  viewBox="0 0 59 60"
                  fill="none"
                >
                  <path
                    d="M1 59H23C42.33 59 58 43.33 58 24V1"
                    stroke="#9EFF00"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
