import { Link } from "react-router-dom";
import Button from "components/Button";
import React from "react";
import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";

export default function SocialRevolution() {
  return (
    <div className="relative px-4 pb-8 lg:pb-16 pt-8 lg:pt-[100px]  xl:pt-[100px] xxl:pt-[130px]">
      <div className="relative mx-auto max-w-[1800px] px-4">
        <div className="relative max-w-[1334px] mx-auto text-center z-10">
          <SectionTag text="Discover your Maian" />
          <SectionTitle
            text="Join the Social Revolution with Maians"
            highligh
          />

          <div className="max-w-[932px] mx-auto">
            <SectionDetail text="Discover the unparalleled possibilities of Maians in customization, secure digital identity, and brand engagement. Built on Vanar Chain’s robust blockchain technology, Maians revolutionize self-expression and identity." />

            <p className="text-sm lg:text-xl font-medium leading-2 lg:leading-7 ">
              Embrace the future of online interaction and identity with Maians.
            </p>
          </div>
        </div>

        <div className="relative flex align-center justify-center mt-8 lg:mt-[50px]">
          <Link
            to={process.env.REACT_APP_MAIANS_BUILD_URL}
            className="inline-flex justify-center items-center bg-transparent text-sm  lg:text-base leading-none font-400 font-serif border-2 rounded-full primary-button glow p-[20px] lg:p-[24px] hover:shadow-3xl hover:shadow-lime"
            target="_blank"
          >
            Discover Your Maian
            <span className="ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M3.375 15.125L14.625 3.875L3.375 15.125ZM14.625 3.875H6.1875H14.625ZM14.625 3.875V12.3125V3.875Z"
                  fill="white"
                />
                <path
                  d="M3.375 15.125L14.625 3.875M14.625 3.875H6.1875M14.625 3.875V12.3125"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
