import { doc, getFirestore, setDoc,getDoc } from "firebase/firestore";
import { app } from "../firebase";

const db = getFirestore(app);

export default class Common {
  static async setDocument(collectionPath, docId, data) {
      await setDoc(doc(db, collectionPath, docId), data, { merge: true });
  }
  static async getSingleDocument(collectionPath, docId) {
    const docRef = doc(db, collectionPath, docId);
    const docSnap = await getDoc(docRef);
    let docData = null;
    if (docSnap.exists()) {
      docData = docSnap.data();
    } else {
      // console.log("No such document!")
    }

    return docData;
  }
}
