import React from "react";
import { REACT_APP_ASSETS_URL } from "constants/constant";
import {
  SectionTag,
  SectionTitle,
  SectionDetail,
} from "components/SectionContent";

export default function AiPowered() {
  return (
    <div className="aiPowered relative w-full px-4 pb-8 pt-8 lg:pt-[100px] lg:pb-16 xl:pt-[100px] xxl:pt-[130px]">
      <div className="relative mx-auto max-w-[1800px]">
        <div className="relative max-w-[932px] mx-auto text-center z-10">
          <SectionTag text="Empower your identity" />
          <SectionTitle text="AI-Powered" highligh />

          <SectionDetail
            text="Maians are conversational intelligent digital assistants that you
            can craft a unique persona for. It's your personal avatar, with its
            own AI-generated backstory. It can act as your representative,
            searching for things you have an interest in, carrying out tasks,
            and can even perform transactions for you. This advanced AI
            integration transforms Maians into true companions in the digital
            realm."
          />
        </div>
      </div>

      <div className="relative flex justify-center flex-col lg:flex-row mx-auto max-w-[1800px] w-full xl:min-h-[990px] xxl:min-h-[1003px] mt-8 lg:mt-[60px] ">
        <div className="ai-img relative w-full rounded-2xl lg:rounded-[32px] overflow-hidden">
          <img
            src={`${REACT_APP_ASSETS_URL}ai-bg.webp`}
            alt="ai powered"
            className="relative object-cover p-[10px] lg:p-[12px]"
          />
        </div>
      </div>
    </div>
  );
}
