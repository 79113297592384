import React, { useState, useEffect, useRef } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { REACT_APP_ASSETS_URL } from "constants/constant";
import {
  SectionDetail,
  SectionTag,
  SectionTitle,
} from "components/SectionContent";

export default function Marketplace() {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
  });

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1366);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const scaleDimensions = () => {
    return isMobile ? [1, 1] : [0.9, 1];
  };

  const rotate = useTransform(scrollYProgress, [0, 1], [10, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions());
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100]);
  return (
    <div
      className="marketplace relative px-4 pb-16 z-10"
      ref={containerRef}
    >
      <div className="relative mx-auto max-w-[1800px] ">
        <div className="relative max-w-[1029px] mx-auto text-center z-10">
          <SectionTag text="Unleash your Creativity" />
          <SectionTitle text="A Marketplace as unique as You" highligh />
          <SectionDetail
            text="Dive into the Maian Marketplace, a treasure trove of outfits and
            accessories that promise endless customization. With a constantly
            updating collection, your Maian will not just match but lead the
            latest style trends, ensuring your digital persona is as unique and
            dynamic as you are."
          />
        </div>
      </div>

      <div className="video-wrapper relative max-w-[1495px] px-4 mx-auto mt-8 lg:mt-[80px]">
        <div className="relative">
          <div className="after-effect absolute rounded-[16px] lg:rounded-[32px] overflow-hidden">
            <img
              src={`${REACT_APP_ASSETS_URL}video-back-effect.webp`}
              alt="effect"
              className="relative top-0 w-full h-full"
            />
          </div>
          <div
            className="video-holder relative w-full h-full lg:h-[832px] z-10 rounded-[16px] lg:rounded-[32px] overflow-hidden"
            style={{
              perspective: "1000px",
            }}
          >
            <motion.div
              style={{
                rotateX: rotate,
                scale,
              }}
              layout
              className="relative w-full h-full rounded-[16px] lg:rounded-[32px] overflow-hidden"
            >
              <video
                loading="lazy"
                poster={`${REACT_APP_ASSETS_URL}marketplace-vd-poster.webp`}
                autoPlay
                loop
                muted
                playsInline
                style={{ translateY: translate }}
              >
                <source
                  src={`${REACT_APP_ASSETS_URL}empower-video.mp4`}
                  type="video/mp4"
                />
              </video>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
